import Bakes from "../images/bakes.png";
import TechEase from "../images/techease.png";
import Cosmetic from "../images/cosmetic.png";
import Blossom from "../images/blossom.png";
import Health from "../images/health.png";
import Item31 from "../images/item3-1.png";
import Item32 from "../images/item3-2.png";
import Item33 from "../images/item3-3.png";
import Item34 from "../images/item3-4.png";
import Edu from "../images/education.png";
import Estate from "../images/estate.png";
import Agric from "../images/agriculture.png";
import Pet from "../images/pet.png";
import Energy from "../images/energy.png";
import Beauty from "../images/beauty.png";
import Mobile from "../images/mobile.png";

export const item1 = [
  {
    id: 1,
    name: "Blissful Bakes",
    description: "Sweet Haven Bakery",
    img: Bakes,
    amountRaised: "$80,000",
    numberOfInvestor: 5,
    minInvest: "$10,000",
    Raised: "Raised",
    Investors: "Investors",
    MinInvestment: "Min.Investment",
    link: "/opportunity",
  },
  {
    id: 2,
    name: "TechEase Solutions",
    description: "TechHub Solutions",
    img: TechEase,
    amountRaised: "$90,000",
    numberOfInvestor: 10,
    minInvest: "$10,000",
    Raised: "Raised",
    Investors: "Investors",
    MinInvestment: "Min.Investment",
    link: "/opportunity",
  },
  {
    id: 3,
    name: " Blossom Boutique",
    description: "Bloomingdale Flower Market",
    img: Blossom,
    amountRaised: "$90,000",
    numberOfInvestor: 10,
    minInvest: "$10,000",
    Raised: "Raised",
    Investors: "Investors",
    MinInvestment: "Min.Investment",
    link: "/opportunity",
  },
  {
    id: 4,
    name: "Cosmetic Stores",
    description: "Urban centres",
    img: Cosmetic,
    amountRaised: "$80,000",
    numberOfInvestor: 5,
    minInvest: "$10,000",
    Raised: "Raised",
    Investors: "Investors",
    MinInvestment: "Min.Investment",
    link: "/opportunity",
  },
  {
    id: 5,
    name: "Food and Beverage Businesses",
    description: "Urban areas, Nigeria",
    img: Item31,
    amountRaised: "$50,000",
    numberOfInvestor: 10,
    minInvest: "$10,000",
    Raised: "Raised",
    Investors: "Investors",
    MinInvestment: "Min.Investment",
    link: "/opportunity",
  },
];

export const NewItem2 = [
  {
    id: 1,
    name: " Blossom Boutique",
    description: "Bloomingdale Flower Market",
    img: Item32,
    amountRaised: "$400,500",
    numberOfInvestor: 5,
    minInvest: "$40,000",
    Raised: "Raised",
    Investors: "Investors",
    MinInvestment: "Min.Investment",
    link: "/opportunity",
  },
  {
    id: 2,
    name: " Blossom Boutique",
    description: "Bloomingdale Flower Market",
    img: Item33,
    amountRaised: "$400,500",
    numberOfInvestor: 5,
    minInvest: "$40,000",
    Raised: "Raised",
    Investors: "Investors",
    MinInvestment: "Min.Investment",
    link: "/opportunity",
  },
  {
    id: 3,
    name: " Blossom Boutique",
    description: "Bloomingdale Flower Market",
    img: Cosmetic,
    amountRaised: "$400,500",
    numberOfInvestor: 5,
    minInvest: "$40,000",
    Raised: "Raised",
    Investors: "Investors",
    MinInvestment: "Min.Investment",
    link: "/opportunity",
  },
  {
    id: 4,
    name: " Blossom Boutique",
    description: "Bloomingdale Flower Market",
    img: Blossom,
    amountRaised: "$400,500",
    numberOfInvestor: 5,
    minInvest: "$40,000",
    Raised: "Raised",
    Investors: "Investors",
    MinInvestment: "Min.Investment",
    link: "/opportunity",
  },
  {
    id: 5,
    name: "Blissful Bakes",
    description: "Sweet Haven Bakery",
    img: Bakes,
    amountRaised: "$80,000",
    numberOfInvestor: 5,
    minInvest: "$10,000",
    Raised: "Raised",
    Investors: "Investors",
    MinInvestment: "Min.Investment",
    link: "/opportunity",
  },
];

export const item2 = [
  {
    id: 1,
    name: "Health and Wellness Services",
    description: "Los Angeles, California,",
    img: Health,
    amountRaised: "$100,000",
    numberOfInvestor: 5,
    minInvest: "$10,000",
    Raised: "Raised",
    Investors: "Investors",
    MinInvestment: "Min.Investment",
    link: "/opportunity",
  },
  {
    id: 2,
    name: "Renewable Energy Ventures",
    description: "Lagos and Abuja",
    img: Energy,
    amountRaised: "$90,000",
    numberOfInvestor: 10,
    minInvest: "$10,000",
    Raised: "Raised",
    Investors: "Investors",
    MinInvestment: "Min.Investment",
    link: "/opportunity",
  },
  {
    id: 3,
    name: "Beauty and Wellness Services",
    description: "Lagos state, Nigeria",
    img: Beauty,
    amountRaised: "$90,000",
    numberOfInvestor: 10,
    minInvest: "$10,000",
    Raised: "Raised",
    Investors: "Investors",
    MinInvestment: "Min.Investment",
    link: "/opportunity",
  },
  {
    id: 4,
    name: "Mobile Repair Services",
    description: " Urban and suburban areas, Nigeria",
    img: Mobile,
    amountRaised: "$80,000",
    numberOfInvestor: 5,
    minInvest: "$10,000",
    Raised: "Raised",
    Investors: "Investors",
    MinInvestment: "Min.Investment",
    link: "/opportunity",
  },
  {
    id: 5,
    name: "Cosmetic Stores",
    description: "Urban centres",
    img: Cosmetic,
    amountRaised: "$50,000",
    numberOfInvestor: 10,
    minInvest: "$10,000",
    Raised: "Raised",
    Investors: "Investors",
    MinInvestment: "Min.Investment",
    link: "/opportunity",
  },
  {
    id: 6,
    name: " Blossom Boutique",
    description: "Bloomingdale Flower Market",
    img: Blossom,
    amountRaised: "$400,500",
    numberOfInvestor: 5,
    minInvest: "$40,000",
    Raised: "Raised",
    Investors: "Investors",
    MinInvestment: "Min.Investment",
    link: "/opportunity",
  },
  {
    id: 7,
    name: " Blossom Boutique",
    description: "Bloomingdale Flower Market",
    img: Bakes,
    amountRaised: "$400,500",
    numberOfInvestor: 5,
    minInvest: "$40,000",
    Raised: "Raised",
    Investors: "Investors",
    MinInvestment: "Min.Investment",
    link: "/opportunity",
  },
  {
    id: 8,
    name: " Blossom Boutique",
    description: "Bloomingdale Flower Market",
    img: Item32,
    amountRaised: "$400,500",
    numberOfInvestor: 5,
    minInvest: "$40,000",
    Raised: "Raised",
    Investors: "Investors",
    MinInvestment: "Min.Investment",
    link: "/opportunity",
  },
];

export const item4 = [
  {
    id: 1,
    name: " Blossom Boutique",
    description: "Bloomingdale Flower Market",
    img: Item34,
    amountRaised: "$400,500",
    numberOfInvestor: 5,
    minInvest: "$40,000",
    Raised: "Raised",
    Investors: "Investors",
    MinInvestment: "Min.Investment",
    link: "/opportunity",
  },
  {
    id: 2,
    name: " Blossom Boutique",
    description: "Bloomingdale Flower Market",
    img: TechEase,
    amountRaised: "$400,500",
    numberOfInvestor: 5,
    minInvest: "$40,000",
    Raised: "Raised",
    Investors: "Investors",
    MinInvestment: "Min.Investment",
    link: "/opportunity",
  },
  {
    id: 3,
    name: "Education and E-Learning",
    description: "Bloomingdale Flower Market",
    img: Edu,
    amountRaised: "$400,500",
    numberOfInvestor: 5,
    minInvest: "$40,000",
    Raised: "Raised",
    Investors: "Investors",
    MinInvestment: "Min.Investment",
    link: "/opportunity",
  },
  {
    id: 4,
    name: "Real Estates",
    description: " Major cities like Lagos and Abuja",
    img: Estate,
    amountRaised: "$400,500",
    numberOfInvestor: 5,
    minInvest: "$40,000",
    Raised: "Raised",
    Investors: "Investors",
    MinInvestment: "Min.Investment",
    link: "/opportunity",
  },
  {
    id: 5,
    name: "Agriculture and Agribusiness",
    description: " Ogun, Kano, and Kaduna",
    img: Agric,
    amountRaised: "$400,500",
    numberOfInvestor: 5,
    minInvest: "$40,000",
    Raised: "Raised",
    Investors: "Investors",
    MinInvestment: "Min.Investment",
    link: "/opportunity",
  },
  {
    id: 6,
    name: "Pet Grooming and Pet Shops",
    description: "Urban areas with pet owners",
    img: Pet,
    amountRaised: "$400,500",
    numberOfInvestor: 5,
    minInvest: "$40,000",
    Raised: "Raised",
    Investors: "Investors",
    MinInvestment: "Min.Investment",
    link: "/opportunity",
  },
  {
    id: 7,
    name: "Food and Beverage Businesses",
    description: "Urban areas, Nigeria",
    img: Item31,
    amountRaised: "$400,500",
    numberOfInvestor: 5,
    minInvest: "$40,000",
    Raised: "Raised",
    Investors: "Investors",
    MinInvestment: "Min.Investment",
    link: "/opportunity",
  },
  {
    id: 8,
    name: "Art and Craft Stores",
    description: "Cultural Hub, Abuja",
    img: Item32,
    amountRaised: "$400,500",
    numberOfInvestor: 5,
    minInvest: "$40,000",
    Raised: "Raised",
    Investors: "Investors",
    MinInvestment: "Min.Investment",
    link: "/opportunity",
  },
];

export const tran = [
  {
    id: 1,
    name: "Blissfull bakes",
    percent: "10%",
    amount: "$30,000",
  },
  {
    id: 2,
    name: "TechEase Solutions",
    percent: "20%",
    amount: "$50,000",
  },
  {
    id: 3,
    name: "Blissfull bakes",
    percent: "10%",
    amount: "$20,000",
  },
];

export const item3 = [
  {
    id: 1,
    name: "Food and Beverage Businesses",
    description: "Urban areas, Nigeria",
    img: Item31,
    amountRaised: "$80,000",
    numberOfInvestor: 5,
    minInvest: "$10,000",
    Raised: "Raised",
    Investors: "Investors",
    MinInvestment: "Min.Investment",
    link: "/opportunity",
  },
  {
    id: 2,
    name: "Art and Craft Stores",
    description: "Cultural Hub, Abuja",
    img: Item32,
    amountRaised: "$90,000",
    numberOfInvestor: 10,
    minInvest: "$10,000",
    Raised: "Raised",
    Investors: "Investors",
    MinInvestment: "Min.Investment",
    link: "/opportunity",
  },
  {
    id: 3,
    name: "Poultry Farming",
    description: "Ogun State, Kano State",
    img: Item33,
    amountRaised: "$90,000",
    numberOfInvestor: 10,
    minInvest: "$10,000",
    Raised: "Raised",
    Investors: "Investors",
    MinInvestment: "Min.Investment",
    link: "/opportunity",
  },
  {
    id: 4,
    name: "Blissful Bakes",
    description: "Sweet Haven Bakery",
    img: Item34,
    amountRaised: "$80,000",
    numberOfInvestor: 5,
    minInvest: "$10,000",
    Raised: "Raised",
    Investors: "Investors",
    MinInvestment: "Min.Investment",
    link: "/opportunity",
  },
  {
    id: 5,
    name: "Cosmetic Stores",
    description: "Urban centres",
    img: Cosmetic,
    amountRaised: "$50,000",
    numberOfInvestor: 10,
    minInvest: "$10,000",
    Raised: "Raised",
    Investors: "Investors",
    MinInvestment: "Min.Investment",
    link: "/opportunity",
  },
  {
    id: 6,
    name: " Blossom Boutique",
    description: "Bloomingdale Flower Market",
    img: Health,
    amountRaised: "$400,500",
    numberOfInvestor: 5,
    minInvest: "$40,000",
    Raised: "Raised",
    Investors: "Investors",
    MinInvestment: "Min.Investment",
    link: "/opportunity",
  },
  {
    id: 7,
    name: " Blossom Boutique",
    description: "Bloomingdale Flower Market",
    img: Blossom,
    amountRaised: "$400,500",
    numberOfInvestor: 5,
    minInvest: "$40,000",
    Raised: "Raised",
    Investors: "Investors",
    MinInvestment: "Min.Investment",
    link: "/opportunity",
  },
  {
    id: 8,
    name: " Blossom Boutique",
    description: "Bloomingdale Flower Market",
    img: Item31,
    amountRaised: "$400,500",
    numberOfInvestor: 5,
    minInvest: "$40,000",
    Raised: "Raised",
    Investors: "Investors",
    MinInvestment: "Min.Investment",
    link: "/opportunity",
  },
  {
    id: 9,
    name: " Blossom Boutique",
    description: "Bloomingdale Flower Market",
    img: Item32,
    amountRaised: "$400,500",
    numberOfInvestor: 5,
    minInvest: "$40,000",
    Raised: "Raised",
    Investors: "Investors",
    MinInvestment: "Min.Investment",
    link: "/opportunity",
  },
];
