import Mike from "../images/mike.jpg";
import Mary from "../images/mary.jpg";
import investor4 from "../images/investor4.png";
import investor5 from "../images/investor5.png";
import investor6 from "../images/investor6.png";
import investor7 from "../images/investor7.png";
import investor8 from "../images/investor8.png";
import investor9 from "../images/investor9.png";
import investor10 from "../images/investor10.png";
import investor11 from "../images/investor11.png";
import investor12 from "../images/investor12.png";
import investor13 from "../images/investor13.png";
import Olatorera from "../images/olatorera.png";

export const investor1 = [
  {
    id: 1,
    pic: Mike,
    name: "Mike Adenuga",
    description: "Founder and Chairman of Globacom",
  },
  {
    id: 2,
    pic: Mary,
    name: "Mary davis",
    description: "Staff of Berkshire Hathaway",
  },
  {
    id: 3,
    pic: Olatorera,
    name: "Olatorera Oniru",
    description: "CEO of Dressmeoutlet.com",
  },
  {
    id: 4,
    pic: investor4,
    name: "John smart",
    description: "Staff of Berkshire Hathaway",
  },
  {
    id: 5,
    pic: investor5,
    name: "Mary davis",
    description: "Staff of Berkshire Hathaway",
  },
  {
    id: 6,
    pic: investor6,
    name: "Peter Rush",
    description: "Staff of Berkshire Hathaway",
  },
  {
    id: 7,
    pic: investor7,
    name: "Julie Steph",
    description: "Staff of Berkshire Hathaway",
  },
  {
    id: 8,
    pic: investor8,
    name: "Emmy Hope",
    description: "Staff of Berkshire Hathaway",
  },
  {
    id: 9,
    pic: investor4,
    name: "Femi Otedola",
    description: "CEO of Zenon Petroleum and Forte Oil",
  },
];

export const Popularinvestor2 = [
  {
    id: 1,
    pic: investor5,
    name: "Toyin Sanni",
    description: " CEO of Emerging Africa Capital Group",
  },
  {
    id: 2,
    pic: investor6,
    name: "Robert Daniel",
    description: "CEO of Icahn Enterprises",
  },
  {
    id: 3,
    pic: investor7,
    name: "Nkemdilim Begho",
    description: "Founder and Managing Director of...",
  },
  {
    id: 4,
    pic: investor8,
    name: "Jude Uzonwanne",
    description: "Staff of Berkshire Hathaway",
  },
  {
    id: 5,
    pic: investor4,
    name: "Stephen Robert",
    description: "Staff of Meta",
  },
  {
    id: 6,
    pic: investor5,
    name: "Rcheal Mary",
    description: "Staff of Berkshire Hathaway",
  },
  {
    id: 7,
    pic: investor6,
    name: "Paschal John",
    description: "Staff of Berkshire Hathaway",
  },
  {
    id: 8,
    pic: investor7,
    name: "Mary davis",
    description: "Staff of Berkshire Hathaway",
  },
];

export const investor2 = [
  {
    id: 1,
    pic: investor9,
    name: "Jude Uzonwanne",
    description: "CEO of Eagle Global Markets",
  },
  {
    id: 2,
    pic: investor10,
    name: "Emily davis",
    description: "Co-founder of Berkshire Hathaway",
  },
  {
    id: 3,
    pic: investor11,
    name: "Atedo Peterside",
    description: "Founder and Chairman of ANAP...",
  },
  {
    id: 4,
    pic: investor12,
    name: "Joseph Daniel",
    description: "Asst, manager of Icahn Enterprises",
  },
  {
    id: 5,
    pic: investor13,
    name: "Isabella davis",
    description: "Asst, Manager of Berkshire Hathaway",
  },
  {
    id: 6,
    pic: Mary,
    name: "Mary davis",
    description: "Staff of Berkshire Hathaway",
  },
  {
    id: 7,
    pic: investor7,
    name: "Joy davis",
    description: "Staff of Berkshire Hathaway",
  },
  {
    id: 8,
    pic: investor8,
    name: "Joshua Emmy",
    description: "Staff of Berkshire Hathaway",
  },
];

export const investor3 = [
  {
    id: 1,
    pic: investor10,
    name: "Mary davis",
    description: "Staff of Berkshire Hathaway",
  },
  {
    id: 2,
    pic: investor5,
    name: "Mary davis",
    description: "Staff of Berkshire Hathaway",
  },
  {
    id: 3,
    pic: investor6,
    name: "Mary davis",
    description: "Staff of Berkshire Hathaway",
  },
  {
    id: 4,
    pic: investor7,
    name: "Mary davis",
    description: "Staff of Berkshire Hathaway",
  },
  {
    id: 5,
    pic: investor8,
    name: "Mary davis",
    description: "Staff of Berkshire Hathaway",
  },
  {
    id: 6,
    pic: investor4,
    name: "Mary davis",
    description: "Staff of Berkshire Hathaway",
  },
  {
    id: 7,
    pic: investor5,
    name: "Mary davis",
    description: "Staff of Berkshire Hathaway",
  },
  {
    id: 8,
    pic: investor6,
    name: "Mary davis",
    description: "Staff of Berkshire Hathaway",
  },
  {
    id: 9,
    pic: investor7,
    name: "Mary davis",
    description: "Staff of Berkshire Hathaway",
  },
];
